import React, { Component } from "react"
import Sketch from "react-p5"
import Maze from "./MazeGen"
const FrameRate = 100
const cellWidth = 10
const width = 500
const height = 500
const cols = width / cellWidth
const rows = height / cellWidth

const pos = cell => ({
    x: cell.xCoord * cellWidth,
    y: cell.yCoord * cellWidth,
})

let state = Maze.initialState(cellWidth, cols, rows)


export default class MazeDraw extends Component {


    setup = (p5) => {
        var myCanvas = p5.createCanvas(width, height);
        myCanvas.parent("canvas");
        // TODO Code Here

        state = Maze.setupState(state)

        
    }

    draw = p5 => {
        p5.background(50);
        // TODO Code here
        const drawGrid = state.grid
        // * Draw the Grid in the canvas  * //

        for (let i = 0; i < drawGrid.length; i++) {
            p5.stroke(255)
            const drawCell = drawGrid[i]

            const posi = pos(drawCell)

            if (drawCell.walls[0])
                p5.line(posi.x, posi.y, posi.x + cellWidth, posi.y)

            if (drawCell.walls[1])
                p5.line(posi.x + cellWidth, posi.y, posi.x + cellWidth, posi.y + cellWidth)

            if (drawCell.walls[2])
                p5.line(posi.x + cellWidth, posi.y + cellWidth, posi.x, posi.y + cellWidth)

            if (drawCell.walls[3])
            p5.line(posi.x, posi.y + cellWidth, posi.x, posi.y)
        }



        // * Draw cell and neighbours * //
        
        let drawCell = state.currentCell
        
        
        let posi = pos(drawCell)
        p5.noStroke()
        p5.fill(0, 255, 0, 100)
        p5.rect(posi.x, posi.y, cellWidth, cellWidth)
        drawCell.neighbors.forEach(neighbor => {
            const positron = pos(neighbor)
            p5.noStroke()
            p5.fill(125, 0, 125, 100)
            p5.rect(positron.x, positron.y, cellWidth, cellWidth)
        });
        posi = pos(drawCell)
        p5.noStroke()
        p5.fill(0, 255, 0, 100)
        p5.rect(posi.x, posi.y, cellWidth, cellWidth)


        p5.frameRate(FrameRate)
        state = Maze.next(state)

        if(!state.currentCell){ p5.noLoop(), console.log("DOOOONE");}
        
    };
    render() {
        return <Sketch setup={this.setup} draw={this.draw} />;
    }
}