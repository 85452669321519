const initCell = (x, y) => ({
    xCoord: x,
    yCoord: y,
    neighbors: [],
    visited: false,
    walls: [true, true, true, true]
})


const hasNeighbours = (state, cell) => {
    let passCell = cell
    const x = passCell.xCoord, y = passCell.yCoord
    const passGrid = state.grid


    let topNeighbour = getNeighbour(x, y - 1, passGrid, state)
    let rightNeighbour = getNeighbour(x + 1, y, passGrid, state)
    let bottomNeighbour = getNeighbour(x, y + 1, passGrid, state)
    let leftNeighbour = getNeighbour(x - 1, y, passGrid, state)

    if (topNeighbour && !topNeighbour.visited)
        passCell.neighbors.push(topNeighbour)

    if (rightNeighbour && !rightNeighbour.visited)
        passCell.neighbors.push(rightNeighbour)

    if (bottomNeighbour && !bottomNeighbour.visited)
        passCell.neighbors.push(bottomNeighbour)

    if (leftNeighbour && !leftNeighbour.visited)
        passCell.neighbors.push(leftNeighbour)

    return passCell
}

const index = (x, y, cols) => x + y * cols
const getNeighbour = (x, y, grid, state) => {
    if (x < 0 || y < 0 || x > state.cols - 1 || y > state.rows - 1) { return null }
    return grid[index(x, y, state.cols)]
}

const getNext = cell => {
    if (cell.neighbors) {
        let posNeighboures = []
        cell.neighbors.forEach(neighbor => {
            if(!neighbor.visited) posNeighboures.push(neighbor)
        });

        const r = Math.floor(Math.random() * posNeighboures.length)
        return posNeighboures[r]
    } else {
        return null
    }
}

const removeWalls = (current,next) => {
    let cells = {current,next}
    const rmv = toRemove(cells.current, cells.next)

    cells.current.walls = rmv.cur
    cells.next.walls = rmv.nxt

    return cells
}

const toRemove = (crt, next) => {
    //* eine Celle kommt rein *//

    const x = crt.xCoord - next.xCoord
    const y = crt.yCoord - next.yCoord

    let cur = crt.walls, nxt = next.walls

    if (x === 1) cur[3] = false, nxt[1] = false
    if (x === -1) cur[1] = false, nxt[3] = false
    if (y === 1) cur[0] = false, nxt[2] = false
    if (y === -1) cur[2] = false, nxt[0] = false
    
    return {cur,nxt}
}

const hasNeighboursLeft = cell => {
    let bool = false
    cell.neighbors.forEach(neighbor => {
        if (!neighbor.visited) bool = true
    });
    return bool
}


export default { initCell, hasNeighbours, getNext, removeWalls , hasNeighboursLeft}