import React from 'react'
import Astar from '../Algorithms/NewAstar/AStarDraw'


    function NewAstarPage() {
    return (
        <div className="main">
            <div className="main__container">
                <div className="main__content">
                    <div id = "canvas"></div>
                    <Astar/>
                </div>
            </div>
        </div>
    )
}
export default NewAstarPage