const colorClosed = (p5, closedList, cellWidth, cellheight) => {

    closedList.forEach(cell => {
        const p = pos(cell, cellWidth, cellheight)
        p5.push()
        p5.fill(100, 0, 0)
        p5.rect(p.x, p.y, cellWidth, cellheight)
        p5.pop()
    });
}
const colorOpen = (p5, openList, cellWidth, cellheight) => {

    openList.forEach(cell => {
        const p = pos(cell, cellWidth, cellheight)
        p5.push()
        p5.fill(0, 100, 0)
        p5.rect(p.x, p.y, cellWidth, cellheight)
        p5.pop()
    });

}
const showCurrent = (p5, cell, cellWidth, cellheight) => {

    p5.push()
    p5.fill(0, 255, 0)
    let node = cell
    const p = pos(node, cellWidth, cellheight)
    p5.rect(p.x, p.y, cellWidth, cellheight)
    p5.pop()
}
const middelCircle = (p5, grid, cellWidth, cellheight) => {
    grid.forEach(cell => {
        const p = posMid(cell, cellWidth, cellheight)
        p5.push()
        if (cell.wall) p5.fill(0)
        else p5.fill(225)
        p5.circle(p.x, p.y, Math.min(cellWidth, cellheight) / 5)
        p5.pop()
    });

}
const showPath = (p5, path, cellWidth, cellheight) => {

    path.forEach((cell, index) => {
        let nextCell = path[index + 1]
        if (!nextCell) nextCell = cell
        const p = posMid(cell, cellWidth, cellheight)
        const pNext = posMid(nextCell, cellWidth, cellheight)

        p5.push()
        p5.strokeWeight(5)
        p5.line(p.x, p.y, pNext.x, pNext.y)
        p5.pop()
    });
}

const pos = (cell, cellWidth, cellheight) => ({
    x: cell.x * cellWidth,
    y: cell.y * cellheight,
})

const posMid = (cell, cellWidth, cellheight) => ({
    x: cell.x * cellWidth + cellWidth / 2,
    y: cell.y * cellheight + cellheight / 2,
})

export default { colorClosed, colorOpen, showCurrent, middelCircle, showPath }