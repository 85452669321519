import React from 'react';
import { Routes, Route } from 'react-router-dom'
import './App.css'


import { Navbar, HeroSection} from "./Components/componentImport"
import {Challenges, SortPage, MazePage, FunkSnakePage, Page404, NewMazePage, NewAstarPage } from "./Pages/PageImport"



function App() {
	return (
		<>
			<Navbar />
			<Routes>
				<Route path='/' element={<HeroSection />} />
				<Route path='/FuncSnake' element={<FunkSnakePage/>} />
				<Route path='/Sort' element={<SortPage />} />
				<Route path='/Labyrinth' element={< MazePage />} />
				<Route path='/Challenges' element={< Challenges/>}/>
				<Route path='/NewMaze' element={<NewMazePage/>}/>
				<Route path='/404' element={< Page404/>}/>
				<Route path='*' element={< Page404 />}/>
				<Route path='/NewAstar' element={< NewAstarPage/>}/>
			</Routes>
		</>

	);
}

export default App;
