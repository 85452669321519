import React, { Component } from "react"
import Sketch from "react-p5"
import Cell from "./cell"

let cols, rows;
let w = 40;
let grid = [];
let current;
let stack = [];



export default class Maze extends Component {

	setup = p5 => {
		var myCanvas = p5.createCanvas(400, 400);
		myCanvas.parent("canvas");
		
		cols = p5.floor(p5.width / w);
		rows = p5.floor(p5.height / w);

		for (let x = 0; x < rows; x++) {
			for (let y = 0; y < cols; y++) {
				let cell = new Cell(x, y, cols, rows, w);
				grid.push(cell);
			}
		}
		current = grid[0];

		// p5.frameRate(1);
	}
	draw = p5 => {
		p5.background(50);
		
		
		// console.log("grid", grid);
		// console.log("current", current);

		// p5.noLoop();

		let x = current.i * w;
		let y = current.j * w;
		

		//STEP 1
		for (let i = 0; i < grid.length; i++) {

			// *Show Function*
			
			p5.stroke(255);
			// p5.noFill();
			// p5.rect(x,y,w,w);

			//Top line
			if (current.walls[0])
				p5.line(x, y, x + w, y);
			//Right Line
			if (current.walls[1])
				p5.line(x + w, y, x + w, y + w);
			//Bottom Line
			if (current.walls[2])
				p5.line(x + w, y + w, x, y + w);
			//Left Line
			if (current.walls[3])
				p5.line(x, y + w, x, y);
			if (current.visited) {
				p5.noStroke();
				p5.fill(255, 0, 255, 100);
				p5.rect(x, y, w, w)
			}
			// *Show Funktion End*
		}

			// *Highlight Function*

			p5.noStroke();
			p5.fill(0, 255, 0, 200);
			p5.rect(x, y, w, w);

			// *Highlight Function End*


			current.visited = true;
			let next = current.hasNeighbours(grid)
			if (next) {
				next.visited = true;
				//STEP 2
				stack.push(current);
				//STEP 3
				this.removeWall(current, next);
				//STEP 4
				current = next;
			} else if (stack.length > 0) {
				current = stack.pop();
			}
	};

	removeWall(cur, nxt) {
		let x = cur.i - nxt.i;
		let y = cur.j - nxt.j;

		if (x === 1) {
			cur.walls[3] = false;
			nxt.walls[1] = false;
		}
		else if (x === -1) {
			cur.walls[1] = false;
			nxt.walls[3] = false;
		}
		if (y === 1) {
			cur.walls[0] = false;
			nxt.walls[2] = false;
		}
		else if (y === -1) {
			cur.walls[2] = false;
			nxt.walls[0] = false;
		}
	}
	render() {
		return <Sketch setup={this.setup} draw={this.draw} />;
	}
}












