const initalCell = (x, y, wallPer) => ({
    x,
    y,
    neighbours: [],
    neighboursWall: [],
    wall: isWall(wallPer),
    f: Infinity,
    g: Infinity,
    h: 0,
    from: null,

})

const isWall = percent => {
    const rnd = Math.floor(Math.random() * 100)
    if (rnd <= percent) return true
    return false
}

const getNeighbours = (state, cell) => {
    const passCell = cell
    const x = passCell.x, y = passCell.y
    const passGrid = state.grid
    let neighbour = []


    let topNeighbour = hasNeighbour(x, y - 1, passGrid, state)
    let rightNeighbour = hasNeighbour(x + 1, y, passGrid, state)
    let bottomNeighbour = hasNeighbour(x, y + 1, passGrid, state)
    let leftNeighbour = hasNeighbour(x - 1, y, passGrid, state)

    if (topNeighbour && !topNeighbour.wall)
        neighbour.push(topNeighbour)

    if (rightNeighbour && !rightNeighbour.wall)
        neighbour.push(rightNeighbour)

    if (bottomNeighbour && !bottomNeighbour.wall)
        neighbour.push(bottomNeighbour)

    if (leftNeighbour && !leftNeighbour.wall)
        neighbour.push(leftNeighbour)

    return neighbour
}

const getWallNeighbours = (state, cell) => {
    const passCell = cell
    const x = passCell.x, y = passCell.y
    const passGrid = state.grid
    let neighbour = []


    let topNeighbour = hasNeighbour(x, y - 1, passGrid, state)
    let rightNeighbour = hasNeighbour(x + 1, y, passGrid, state)
    let bottomNeighbour = hasNeighbour(x, y + 1, passGrid, state)
    let leftNeighbour = hasNeighbour(x - 1, y, passGrid, state)

    if (topNeighbour && topNeighbour.wall)
        neighbour.push(topNeighbour)

    if (rightNeighbour && rightNeighbour.wall)
        neighbour.push(rightNeighbour)

    if (bottomNeighbour && bottomNeighbour.wall)
        neighbour.push(bottomNeighbour)

    if (leftNeighbour && leftNeighbour.wall)
        neighbour.push(leftNeighbour)

    return neighbour
}

const index = (x, y, cols) => x + y * cols

const hasNeighbour = (x, y, grid, state) => {
    if (x < 0 || y < 0 || x > state.cols - 1 || y > state.rows - 1) { return null }
    return grid[index(x, y, state.cols)]
}

export default { initalCell, getNeighbours, getWallNeighbours }