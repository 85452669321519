const wallCenter = (p5, cell, cellWidth, cellheight) => {
    const xAbstand = cellWidth / 5
    const yAbstand = cellheight / 5
    const pc = pos(cell, cellWidth, cellheight)

    p5.push()
    p5.fill(0)

    p5.beginShape()

    p5.vertex(pc.x + xAbstand * 2, pc.y + yAbstand)
    p5.vertex(pc.x + cellWidth - xAbstand * 2, pc.y + yAbstand)

    p5.vertex(pc.x + cellWidth - xAbstand, pc.y + yAbstand * 2)
    p5.vertex(pc.x + cellWidth - xAbstand, pc.y + cellheight - yAbstand * 2)

    p5.vertex(pc.x + cellWidth - xAbstand * 2, pc.y + cellheight - yAbstand)
    p5.vertex(pc.x + xAbstand * 2, pc.y + cellheight - yAbstand)

    p5.vertex(pc.x + xAbstand, pc.y + cellheight - yAbstand * 2)
    p5.vertex(pc.x + xAbstand, pc.y + yAbstand * 2)

    p5.endShape(p5.CLOSE)
    p5.pop()
}


const directNeighbour = (p5, cell, cellWidth, cellheight) => {
    const xAbstand = cellWidth / 5
    const yAbstand = cellheight / 5
    const pc = pos(cell, cellWidth, cellheight)

    cell.neighboursWall.forEach(neighbour => {
        const px = cell.x - neighbour.x
        const py = cell.y - neighbour.y
        const pn = pos(neighbour, cellWidth, cellheight)

        // * LINKS RECHTS * //
        if (px === 1) {
            p5.push()
            p5.fill(0)

            p5.beginShape()
            // * open * //
            p5.vertex(pc.x + xAbstand * 2, pc.y + yAbstand)
            p5.vertex(pn.x + cellWidth - xAbstand * 2, pn.y + yAbstand)

            p5.vertex(pn.x + cellWidth - xAbstand * 2, pn.y + cellheight - yAbstand)
            p5.vertex(pc.x + xAbstand * 2, pc.y + cellheight - yAbstand)

            p5.endShape(p5.CLOSE)
            p5.pop()
        }


        // *OBEN UNTEN * //

        if (py === 1) {
            p5.push()
            p5.fill(0)
            p5.beginShape()
            p5.vertex(pc.x + xAbstand, pc.y + yAbstand * 2)
            p5.vertex(pc.x + cellWidth - xAbstand, pc.y + yAbstand * 2)

            p5.vertex(pn.x + cellWidth - xAbstand, pn.y + cellheight - yAbstand * 2)
            p5.vertex(pn.x + xAbstand, pn.y + cellheight - yAbstand * 2)

            p5.endShape(p5.CLOSE)
            p5.pop()
        }
    });

}

const pos = (cell, cellWidth, cellheight) => ({
    x: cell.x * cellWidth,
    y: cell.y * cellheight,
})


export default { wallCenter, directNeighbour }