import base from "./base"


//Constants
const NORTH = { x: 0, y: -1 }
const SOUTH = { x: 0, y: 1 }
const EAST = { x: 1, y: 0 }
const WEST = { x: -1, y: 0 }

//point operators
const pointEq = p1 => p2 => p1.x === p2.x && p1.y === p2.y

//bools
const willEat = state => pointEq(nextHead(state))(state.apple)
const willCrash = state => state.snake.find(pointEq(nextHead(state)))
const validMove = move => state =>
    state.moves[0].x + move.x !== 0 || state.moves[0].y + move.y !== 0

//Next values based on current State

const nextMoves = state => state.moves.length > 1 ? base.dropFirst(state.moves) : state.moves
const nextApple = state => willEat(state) ? rndPos(state) : state.apple
const nextHead = state => state.snake.length === 0
    ? { x: 2, y: 2 }
    : {
        x: base.mod(state.cols)(state.snake[0].x + state.moves[0].x),
        y: base.mod(state.rows)(state.snake[0].y + state.moves[0].y)
    }
const nextSnake = state => willCrash(state)
    ? []
    : (willEat(state)
        ? [nextHead(state)].concat(state.snake)
        : [nextHead(state)].concat(base.dropLast(state.snake)))

const rndPos = table => ({
    x: base.rnd(0)(table.cols - 1),
    y: base.rnd(0)(table.rows - 1)
})

//Initial State
const initialState = () => ({
    cols: 20,
    rows: 20,
    moves: [EAST],
    snake: [],
    apple: { x: 16, y: 5 },
})

const next = state => ({
    rows: state.rows,
    cols: state.cols,
    moves: nextMoves(state),
    snake: nextSnake(state),
    apple: nextApple(state),
})


// const next = spec({
//     rows: prop('rows'),
//     cols: prop('cols'),
//     moves: nextMove,
//     snake: nextSnake,
//     apple: nextApple,
// })

const enqueue = (state, move) => validMove(move)(state)
    ? base.merge(state)({ moves: state.moves.concat([move]) })
    : state


export default { EAST, NORTH, SOUTH, WEST, initialState, enqueue, next, }