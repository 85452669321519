import React from "react";
import{Link} from "react-router-dom"

function Navbar() {
    return (
        <nav className="navbar">
            <div className="navbar__container">
            <Link to= "/" id="navbar__logo"><a ><i className="fa-solid fa-terminal"></i>MN</a></Link>
                <div className="navbar__toggle" id="mobile-menu">
                    <span className="bar"></span> <span className="bar"></span>
                    <span className="bar"></span>
                </div>
                <ul className="navbar__menu">
                    <li className="navbar__item">
                        <Link to= "/" className="navbar__links"><a>Home</a></Link>
                    </li>
                    <li>
                    <Link to= "/Challenges" className="navbar__links">Challenges</Link>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default Navbar;

