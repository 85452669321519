import React, { Component } from "react"
import Sketch from "react-p5"
import { quick, expStates } from "./quicksort";

const w = 4;
let toSort = [];
let sorted = [];

export default class Sort extends Component {
	setup = (p5) => {
		var myCanvas = p5.createCanvas(800, 600);
		myCanvas.parent("canvas");
		// TODO Code Here

		for (let i = 0; i < p5.width / w; i++) {
			sorted.push(p5.floor(p5.map(i, 0, p5.width / w, 1, p5.height)));
		}
		toSort = p5.shuffle(sorted);
		quick(toSort, 0, toSort.length - 1);
		//bubbleSort(toSort);
	}

	draw = p5 => {
		// TODO Code Here
		p5.background(p5.color('#262626'));
		for (let i = 0; i < toSort.length; i++) {
			p5.stroke(0);
			p5.fill(255);
			if (expStates[i] == 0) {
				p5.fill(255, 0, 0);
			} else if (expStates[i] == 1) {
				p5.fill(0, 255, 0);
			} else if (expStates[i] == 2) {
				p5.fill(0, 0, 255);
			} else {
				p5.fill(255);
			}
			p5.rect(i * w, p5.height - toSort[i], w, toSort[i]);
		}
	}

	render() {
		return <Sketch setup={this.setup} draw={this.draw} />;
	}
}



