import Cell from './cell'

const PATHWEIGHT = 1

const initialState = (width, height, cols, rows, wallPercent) => ({
    width,
    height,
    cols,
    rows,
    grid: [],
    start: 0,
    end: cols * rows - 1,
    openList: [],
    closedList: [],
    path: [],
    current: null,
    finished: false,
    noPath: false,
    wallPercent,
})

const setupState = state => {
    let firstState = state

    firstState = gridGen(firstState)
    firstState = initStartup(state)

    return firstState
}

//* Hier Gehts Ab *//
const next = state => {
    let nextState = state

    if (nextState.openList) {
        nextState = getCurrent(state)

        if (nextState.current === nextState.grid[nextState.end]){
            nextState.finished = true
            nextState = getPath(state)
        }

        nextState = removeFromOpen(nextState)
        nextState = getNewScores(nextState)
    }
    return nextState
}

const getPath = state =>{
    let returnState = state
    let PathCell = returnState.current
    while (PathCell !== returnState.grid[returnState.start]){
        returnState.path.push(PathCell)
        PathCell = PathCell.from
    }
    returnState.path.push(PathCell)


    return returnState
}

const getNewScores = state => {
    let returnState = state
    let tmpG = returnState.current.g + PATHWEIGHT
    returnState.current.neighbours.forEach(neighbour => {
        
        if (tmpG < neighbour.g) {
            
            neighbour.from = returnState.current
            neighbour.g = tmpG
            neighbour.f = tmpG + heuristic(neighbour, returnState.grid[returnState.end])
            // * add this neighbour to open list * //
            let isInOpen = false
            let isInClosed = false
            returnState.openList.forEach(cell => {
                if (cell.x === neighbour.x && cell.y === neighbour.y) {
                    cell = neighbour
                    isInOpen = true
                }
            });
            returnState.closedList.forEach(cell => {
                if (cell.x === neighbour.x && cell.y === neighbour.y) {
                    isInClosed = true
                }
            });
            if (!isInOpen && !isInClosed) {
                returnState.openList.push(neighbour)
            }
        }
    });

    // console.log('OPEN',returnState.openList);
    return returnState
}

const removeFromOpen = state => {
    let retState = state

    for (let i = 0; i < retState.openList.length; i++) {
        if (retState.openList[i] === retState.current) {
            retState.openList.splice(i, 1)
        }
    }
    let isInClosed = false
    retState.closedList.forEach(cell => {
        if (cell.x === retState.current.x && cell.y === retState.current.y) {
            isInClosed = true
        }
    });

    if(!isInClosed) retState.closedList.push(retState.current)
    return retState
}

const getCurrent = state => {
    let retState = state
    
    const lowestFIndex = getLowestF(retState)

    retState.current = retState.grid[lowestFIndex]
    
    return retState
}

const getLowestF = state => {
    let retState = state
    let lowstIndex = 0
    let lowstValue = Infinity
    retState.openList.forEach((cell, index) => {
        if (cell.f < lowstValue) {
            lowstValue = cell.f
            lowstIndex = index
        }
    });

    let lowstCell = null

    if(retState.openList){
        lowstCell = retState.openList[lowstIndex]
    }
    if(!lowstCell){
        lowstCell = retState.current
        retState.noPath = true
    }
    if(!retState.noPath){
        retState.grid.forEach((element,index) => {
            if(element.x === lowstCell.x && element.y === lowstCell.y){
                lowstIndex = index
            }
        });
    }

    return lowstIndex
}

const initStartup = state => {
    let retState = state
    const start = retState.start
    const end = retState.end
    retState.openList.push(retState.grid[start])
    retState.grid[retState.start].g = 0
    retState.grid[retState.start].h = heuristic(retState.grid[start], retState.grid[end])
    retState.current = retState.grid[start]
    return retState
}

const gridGen = state => {
    let returnState = state
    let grid = []
    for (let j = 0; j < returnState.rows; j++) {
        for (let i = 0; i < returnState.cols; i++) {
            grid.push(Cell.initalCell(i, j, returnState.wallPercent))
        }
    }
    grid[returnState.start].wall = false
    grid[returnState.end].wall = false
    returnState.grid = grid

    returnState.grid.forEach(cell => {
        cell.neighbours = Cell.getNeighbours(returnState, cell)
        cell.neighboursWall = Cell.getWallNeighbours(returnState, cell)
    });
    return returnState
}
// * euclidian distance * //
const ucl = (cellA, cellB) => {
    const x = cellA.x - cellB.x
    const y = cellA.y - cellB.y
    const sqX = x * x
    const sqY = y * y
    return Math.sqrt(sqX + sqY)*1.25
}

const heuristic = (cellA, cellB) => {
    return ucl(cellA, cellB)

}


export default { initialState, setupState, next }









































































