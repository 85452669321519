import React from 'react'
import {Link} from 'react-router-dom'


	function Challenge() {
	return (
		<div className="main">
			<div className="main__container">
				<div className="main__content">
					<Link to= "/FuncSnake"><button className="main__btn"><a>FunkSnake</a></button></Link>
					<Link to= "/Sort"><button className="main__btn"><a>Sort</a></button></Link>
					<Link to= "/NewMaze"><button className="main__btn"><a>Maze</a></button></Link>
					<Link to= "/NewAstar"><button className="main__btn"><a>AStar</a></button></Link>
				</div>
			</div>
		</div>
	)
}
export default Challenge