import React from 'react'
import Maze from "../Algorithms/MazeGenerator/mazeGen"


function MazePage() {
    return (
        <div className="main">
            <div className="main__container">
                <div className="main__content">
                    <div id="canvas"></div>
                    <Maze />
                </div>
            </div>
        </div>
    )
}
export default MazePage