export default function Cell(y, x, cols) {


    this.i = x;
    this.j = y;
    this.visited = false
    this.walls = [true, true, true, true]

    this.hasNeighbours = function (grid) {
        var neighbour = [];

        var top = grid[this.index(x, y - 1)];
        var right = grid[this.index(x + 1, y)];
        var bottom = grid[this.index(x, y + 1)];
        var left = grid[this.index(x - 1, y)];


        if (top && !top.visited) {
            neighbour.push(top);
        }
        if (right && !right.visited) {
            neighbour.push(right);
        }
        if (bottom && !bottom.visited) {
            neighbour.push(bottom);
        }
        if (left && !left.visited) {
            neighbour.push(left);
        }

        if (neighbour.length > 0) {
            const r = Math.floor(Math.random(0, neighbour.length))
            return neighbour[r];
        } else {
            return undefined;
        }

    }

    this.index = function (i, j) {
        if (i < 0 || j < 0 || i > cols - 1 || j > cols - 1) {
            return -1;
        }
        return i + j * cols;
    }
}


