import React from 'react'
import {Link} from "react-router-dom"


function HeroSection() {
    return (
        <div className="main">
            <div className="main__container">
                <div className="main__content">
                    <h1>Meine Website</h1>
                    <h2>Selbst programmiert</h2>
                    <p>Meine Coding Challenges</p>
                    <Link to= "/Challenges"><button className="main__btn"><a>Challenges</a></button></Link>
                    <p>Ich bin leider kein Designer</p>
                </div>
            </div>
        </div>
    )
}

export default HeroSection