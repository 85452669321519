import React, { Component } from "react";
import Sketch from "react-p5";
import snk from "./snake";
import base from './base'



//Mutable State
let state = snk.initialState()

//Position helper
const height = 600 ;
const x = (c) => Math.round(c * height / state.cols)
const y = (r) => Math.round(r * height / state.rows)


export default class Snake extends Component {

    setup = (p5) => {
        var myCanvas = p5.createCanvas(height, height);
        myCanvas.parent("canvas");
        p5.frameRate(8)
        p5.fill(255)
        p5.rect(200, 200, 50, 50)
    }

    //*Game loop draw
    draw = (p5) => {
        //clear
        p5.background(60, 60, 60)
        state = snk.next(state)

        //draw Snake
        p5.fill(0, 200, 50)
        base.map(p => p5.rect(x(p.x), y(p.y), x(1), y(1)))(state.snake)

        //draw Apple
        p5.fill(255, 50, 0)
        p5.rect(x(state.apple.x), y(state.apple.y), x(1), y(1))

        // add crash
        if (state.snake.length === 0) {
            p5.fill(255, 0, 0)
            p5.rect(0, 0, p5.width, p5.height)
        }
    }
    render() {
        return <Sketch setup={this.setup} draw={this.draw} />;
    }
}

//Key events
window.addEventListener('keydown', e => {
    switch (e.key) {
        case 'w': case 'i': case 'ArrowUp': state    = snk.enqueue(state, snk.NORTH);break
        case 'a': case 'j': case 'ArrowLeft': state  = snk.enqueue(state, snk.WEST); break
        case 's': case 'k': case 'ArrowDown': state  = snk.enqueue(state, snk.SOUTH);break
        case 'd': case 'l': case 'ArrowRight': state = snk.enqueue(state, snk.EAST); break
        default: break
    }
})
