
let states = []

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
async function swap(arr, a, b) {
	await sleep(1);
	let tmp = arr[a];
	arr[a] = arr[b];
	arr[b] = tmp;
}



async function quickSort(arr, start, end) {

    if (start >= end) {
        return;
    }

    let index = await partition(arr, start, end);
    states[index] = -1;
    await Promise.all([
        await quickSort(arr, start, index - 1),
        await quickSort(arr, index + 1, end)
    ]);

}

async function partition(arr, start, end) {
    for (let i = start; i < end; i++) {
        states[i] = 1;
    }
    let pivInd = start;
    let pivVal = arr[end];
    states[end] = 2;
    states[pivInd] = 0;
    for (let i = start; i < end; i++) {
        if (arr[i] < pivVal) {
            await swap(arr, i, pivInd);
            states[pivInd] = -1;
            pivInd++;
            states[pivInd] = 0;
        }
    }
    await swap(arr, pivInd, end);

    for (let i = start; i <= end; i++) {
        states[i] = -1;
    }
    return pivInd;
}
export function quick(arr, start, end){quickSort(arr, start, end)}
export let expStates = states;


