import React from 'react'
import FunkSnake from "../Algorithms/funkSnakep5/web"


function FunkSnakePage() {
    return (
        <div className="main">
            <div className="main__container">
                <div className="main__content">
                    <div id="canvas"></div>
                    <FunkSnake/>
                </div>
            </div>
        </div>
    )
}
export default FunkSnakePage