import Cell from "./Cells"

const initialState = (w, c, r) => ({
    cellWidth: w,
    cols: c,
    rows: r,
    currentCell: null,
    grid: [],
    stack: [],
})

const setupState = state => {
    let passState = state
    // *generade Grid with informations* //
    passState = gridGen(passState)

    // * setup currend cell * //
    passState = setupCurrendCell(passState)
    passState.currentCell.visited = true

    return passState
}

const next = state => {
    let nextState = state
        
    //TODO
    //! 2.2 Weiter Führen 
    if(Cell.hasNeighboursLeft(nextState.currentCell)){
        
        //* add last Currend to stack *//
        nextState.stack.push(nextState.currentCell)
        let nextCell = Cell.getNext(nextState.currentCell)
        //* removeWalls *//
        const modWall = Cell.removeWalls(nextState.currentCell,nextCell)
        nextState.currentCell = modWall.current
        nextCell = modWall.next
        //* get next currendCell *//
        nextState.currentCell = nextCell
        nextState.currentCell.visited = true
    }
    else if(nextState.stack){
        nextState.currentCell = nextState.stack.pop()
    }
    return nextState
}

const gridGen = state => {
    let passState = state
    for (let j = 0; j < state.cols; j++) {
        for (let i = 0; i < state.rows; i++) {
            const cell = Cell.initCell(i, j)
            passState.grid.push(cell)
        }
    }

    passState.grid.forEach((cell, index) => {
        passState.grid[index] = Cell.hasNeighbours(passState, cell)
    });
    return passState
}

const setupCurrendCell = ({cellWidth, cols, rows,  grid, stack}) => ({
    cellWidth,
    cols,
    rows,
    currentCell: grid[0],
    grid,
    stack,
})




export default { initialState, gridGen, setupState, next }