import React, { Component } from "react"
import Sketch from "react-p5"
import AStarAlg from './AStarAlg'
import Shapes from './p5Shapes'
import cg from './cellColor'

const width = 600
const height = 600
const cols = 10
const rows = 10
const wallPercent = 30
const cellWidth = width / cols
const cellheight = height / rows

const pos = cell => ({
    x: cell.x * cellWidth,
    y: cell.y * cellheight,
})



let state = AStarAlg.initialState(width, height, cols, rows, wallPercent)

export default class AStar extends Component {

    setup = (p5) => {
        var myCanvas = p5.createCanvas(width, height);
        myCanvas.parent("canvas");
        p5.background(100)
        // TODO Code Here
        state = AStarAlg.setupState(state)

        // * fill grid * //
        const grid = state.grid
        grid.forEach(cell => {
            if (cell.wall) {
                
                // * Wall shapes center * // 
                Shapes.wallCenter(p5, cell, cellWidth, cellheight)
                // * Wall shapes top, bottom, left, right * //
                Shapes.directNeighbour(p5, cell, cellWidth, cellheight)
            }
            else {
                p5.push()
                p5.fill(100)
                p5.rect(pos(cell).x, pos(cell).y, cellWidth, cellheight)
                p5.pop()
            }
        });
    }

    draw = p5 => {
        // TODO Code here

        p5.frameRate(15)

        cg.colorClosed(p5, state.closedList, cellWidth, cellheight)
        cg.colorOpen(p5, state.openList, cellWidth, cellheight)
        cg.showCurrent(p5, state.current, cellWidth, cellheight)
        cg.middelCircle(p5, state.grid, cellWidth, cellheight)
        cg.showPath(p5, state.path, cellWidth, cellheight)

        if (state.finished) {
            p5.noLoop()
            console.log('! DONE !');
        }
        if (state.noPath) {
            p5.noLoop()
            console.log('No Path found')
        }





        state = AStarAlg.next(state)
    };
    render() {
        return <Sketch setup={this.setup} draw={this.draw} />;
    }
}