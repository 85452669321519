import React from 'react'


    function Page404() {
    return (
        <div className="main">
            <div className="main__container">
                <div className="main__content">
                    <h1>404</h1>
                    <h2>Da ist wohl was schief gelaufen</h2>
                </div>
            </div>
        </div>
    )
}
export default Page404